`
<template>
    <div>
        <div v-if="match">
            <div class="ftb_details_header_section"><!--v-if="header"-->
                <!-- <h3>
                    <a href="javascript:;">
                        {{match.competitors[0].name}} <span>vs</span> {{match.competitors[1].name}}
                    </a>
                </h3> -->
                <div class="ftb_details_header_content">
                    <div class="ftb_details_header_results"
                         v-if="(match.halfTime && match.fullTime) && (match.status == 'Finished' || match.status == 'Finished AET' || match.status == 'Finished AP')">
                        <ul>
                            <li v-if="match.halfTime">
                                <span>
                                {{$t('football.match-detail.first half score')}} : {{match.halfTime}}
                                </span>
                            </li>
                            <li v-if="match.fullTime && (match.status == 'Finished' || match.status == 'Finished AET' || match.status == 'Finished AP')">
                                <!-- v-if="ft && (status == 'Finished' || status == 'Finished AET' || status == 'Finished AP')"-->
                                <span>
                                  {{$t('football.match-detail.second half score')}} : {{calculateFinalScore(match.fullTime,match.halfTime)}}
                                </span>
                            </li>
                        </ul>
                    </div>
                    <div class="ftb_details_header_name_pic_status">
                        <div class="ftb_details_header_name_pic">
                            <strong>
                                <img :alt="match.competitors[0].isHome==true?match.competitors[0].name:match.competitors[1].name"
                                     :src="$storageURL+'images/rugby/'+(match.competitors[0].isHome==true?match.competitors[0].id:match.competitors[1].id)+'_100x100.webp'"
                                     :onerror="$teamDefaultLogo()">
                            </strong>
                            <h3>
                                {{match.competitors[0].isHome==true?match.competitors[0].name:match.competitors[1].name}}
                            </h3>
                        </div>
                        <div class="ftb_details_header_status">
                            <h5>
                                <a href="javascript:;"
                                   v-if="match.status=='NSY' || (match.status=='Result Only' && match.ft == ' - ')">{{match.date
                                    | formatDate4}}</a>
                                <a href="javascript:;"
                                   v-if="(match.status=='Finished'  || match.status == 'Full Time') || (match.minutes=='Finished'  || match.minutes == 'Full Time')">{{match.extraTime
                                    != '' ? $formatFootballScore(match.extraTime) :
                                    $formatFootballScore(match.fullTime)}}</a>
                                <a href="javascript:;" v-if="match.currentPeriod == 'Penalty'">{{match.extraTime=''
                                    ? $formatFootballScore(match.extraTime) : $formatFootballScore(match.fullTime)}}</a>
                                <a href="javascript:;"
                                   v-if="((match.status=='1st Half' || match.status=='2nd Half' || match.status=='Half Time' || match.status=='ET 1st Half' || match.status=='ET 2nd Half' || match.status=='inprogress') && (match.current_period != 'Penalty'))">{{$formatFootballScore(match.currentFullScore)}}</a>
                            </h5>
                            <label v-if="match.status == 'NSY' || match.status == 'Result Only' || match.status == 'Penalty'">
                                {{match.status}}
                            </label>
                            <label v-if="match.status == 'Finished' &&  (match.minutes != 'Finished AET' && match.minutes != 'Finished AP')">
                                FT
                            </label>
                            <label v-if="match.status == 'Finished' && match.status == 'Finished AET'">
                                Fi. AET ({{match.extraTime}})
                            </label>
                            <label v-if="match.status == 'Finished' && match.status == 'Finished AP'">
                                Fi. AP ({{ match.penaltyScore }})
                            </label>
                            <label v-if="((match.status=='1st Half' || match.status=='2nd Half' || match.status=='Half Time' || match.status=='ET 1st Half' || match.status=='ET 2nd Half' || match.status=='inprogress') && (match.current_period != 'Penalty'))">
                                {{ match.minutes }}'
                            </label>
                            <label v-if="((match.status=='1st Half' || match.status=='2nd Half' || match.status=='Half Time' || match.status=='ET 1st Half' || match.status=='ET 2nd Half' || match.status=='inprogress') && (match.current_period == 'Penalty'))">
                                PEN ({{ match.penaltyScore }})
                            </label>
                        </div>
                        <div class="ftb_details_header_name_pic ftb_dtl_away_team">
                            <h3 class="ftb_details_header_name_pic_away">
                                {{match.competitors[0].isHome==false?match.competitors[0].name:match.competitors[1].name}}
                            </h3>
                            <strong>
                                <img :alt="match.competitors[0].isHome==false?match.competitors[0].name:match.competitors[1].name"
                                     :src="$storageURL+'images/rugby/'+(match.competitors[0].isHome==false?match.competitors[0].id:match.competitors[1].id)+'_100x100.webp'"
                                     :onerror="$teamDefaultLogo()">
                            </strong>
                        </div>
                    </div>
                    <div class="rgb_lc_winstatus">
                        <div class="rgb_lc_winstatus_col">
                        <span :class="(h2h == 'W') ? 'rgb_lc_w' : (h2h == 'L') ? 'rgb_lc_l' : 'rgb_lc_d'"
                              v-for="h2h in $getSafe(()=>$filterTeam(match.competitors, true).h2h.status)">
                              {{h2h}}
                        </span>
                        </div>
                        <div class="rgb_lc_winstatus_col">
                        <span :class="(h2h == 'W') ? 'rgb_lc_w' : (h2h == 'L') ? 'rgb_lc_l' : 'rgb_lc_d'"
                              v-for="h2h in $getSafe(()=>$filterTeam(match.competitors, false).h2h.status)">
                              {{h2h}}
                        </span>
                        </div>
                    </div>
                    <div class="rgb_lc_tbs_dt" v-if="match.status != 'NSY'">
                        <div class="rgb_lc_tbs_dt_col">
                            <span :class="(goalType.home == 'TRY') ? 'rgb_lc_selected' : ''" @click="goalType.home = 'TRY'"
                                  style="cursor: pointer">Tries</span>
                            <span :class="(goalType.home == 'PEN') ? 'rgb_lc_selected' : ''" @click="goalType.home = 'PEN'"
                                  style="cursor: pointer">Penalties</span>
                            <span :class="(goalType.home == 'DRO') ? 'rgb_lc_selected' : ''" @click="goalType.home = 'DRO'"
                                  style="cursor: pointer">Drop Goals</span>
                            <div class="rgb_lc_tbs_inr">
                                <label v-for="goal in filterGoal($getSafe(()=>$filterTeam(match.competitors, true).goals.goals), goalType.home)">
                                    {{$getSafe(() =>goal.playerName)}} ({{goal.minute}}')
                                </label>
                            </div>
                        </div>
                        <div class="rgb_lc_tbs_dt_col" v-if="match.status != 'NSY'">
                            <span :class="(goalType.away == 'TRY') ? 'rgb_lc_selected' : ''" @click="goalType.away = 'TRY'"
                                  style="cursor: pointer">Tries</span>
                            <span :class="(goalType.away == 'PEN') ? 'rgb_lc_selected' : ''" @click="goalType.away = 'PEN'"
                                  style="cursor: pointer">Penalties</span>
                            <span :class="(goalType.away == 'DRO') ? 'rgb_lc_selected' : ''" @click="goalType.away = 'DRO'"
                                  style="cursor: pointer">Drop Goals</span>
                            <div class="rgb_lc_tbs_inr rgb_lc_tbs_inr_right">
                                <label v-for="goal in filterGoal($getSafe(()=>$filterTeam(match.competitors, false).goals.goals), goalType.away)">
                                    {{$getSafe(() =>goal.playerName)}} ({{goal.minute}}')
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="sabc_ftbl_listdtl_timer" v-if="left">
                        <span>{{left.days}}</span>
                        <i>:</i>
                        <span>{{left.hours}}</span>
                        <i>:</i>
                        <span>{{left.minutes}}</span>
                        <i>:</i>
                        <span>{{left.seconds}}</span>
                    </div>
                    <div class="ftb_details_header_foot">
                        <ul>
                            <li>
                                <span><b>{{$t('football.match-detail.Date')}} :</b>{{match.date | formatDate('DD/MM/YYYY')}}</span>
                            </li>
                            <li>
                                <span><b>{{$t('football.match-detail.Time')}} :</b>{{match.date | formatDate4}}</span>
                            </li>
                            <li v-if="match.venue_name">
                                <span><b>venue :</b>{{match.venue.name}}</span>
                            </li>
                            <li v-if="match.refree_name">
                                <span><b>Referee :</b>Tsegay Mogos Teklu</span>
                            </li>
                        </ul>
                    </div>
                    <div class="rugby_details_header_scoreticker">
                        <!--TODO Slider below is old section-->
                        <div class="rugby_center_slider_wrap">
                            <!--                            <div :style="{'width': (match.minutes/0.90)+'%'}" class="match_slider_fill"-->
                            <!--                                v-if="match.minutes < 90">-->
                            <!--                            </div>-->
                            <!--                            <div :style="{'width': 100+'%'}"-->
                            <!--                                class="match_slider_fill "-->
                            <!--                                v-if="match.minutes >= 90 || match.minutes == 'Finished' || match.minutes == 'Finished AP' || match.minutes == 'Finished AET' || match.minutes == '90+'">-->
                            <!--                                &lt;!&ndash; v-if="minutes >= 90 || minutes == 'Finished' || minutes == 'Finished AP' || minutes == 'Finished AET' || minutes == '90+'" :style="{'width': 100+'%'}" &ndash;&gt;-->
                            <!--                            </div>-->
                            <!--                            <div :style="{'width': (45/0.90)+'%'}" class="match_slider_fill "-->
                            <!--                                v-if="match.minutes == 'HT'">-->
                            <!--                            </div>-->
                            <div class="rugby_center_slider_wrap" v-if="match.competitors.length">
                                <span class="rugby_matchdtl_head_status">
                                  KO
                                </span>
                                <span class="rugby_matchdtl_head_status">
                                  HT
                                </span>
                                <span class="rugby_matchdtl_head_status">
                                  FT
                                </span>
                                <span :style="{'left': goal.cal_minute+'%'}"
                                      :title="(goal.minutes) +`'`+ ($getSafe(() => goal.player.name))"
                                      class="rugby_center_content_span_img"
                                      v-for="goal in match.goals">
                                    <!-- <strong>{{goal.minutes}}'</strong> -->
                                    <img :alt="(goal.type)+ (goal.minutes)+`'`   +($getSafe(() =>goal.player.name))" :class="(goal.is_home == 1) ? 'home_ticker' : '' "
                                         :src="require('@/assets/images/CONN.webp')"
                                         v-if="goal.type == 'CON'">
                                    <img :alt="(goal.type)+ (goal.minutes)+`'`   +($getSafe(() =>goal.player.name))" :class="(goal.is_home == 1) ? 'home_ticker' : '' "
                                         :src="require('@/assets/images/'+goal.type+'.webp')"
                                         v-else>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="rugby_details_tabs" v-if="tabs">
            <a @click="scrollLeft()" aria-label="previous arrow" class="prev_btn_dtl_tabs" href="javascript:;">
                <img v-if="selectedTabIndex > 0" :src="require('@/assets/images/slider_prev_arrow.webp')" height="21" width="12" alt="previous arrow" >
            </a>
            <ul class="rgby_dtl_tab_mobi">
                <li>
                    <router-link :to="{ name: 'rugby_match_detail.'+tabs[selectedTabIndex]}" class="selected">{{capitalizeSelectedTab}}</router-link>
                </li>
            </ul>
            <ul class="rgby_dtl_tab_desk">
                <li v-for="(tab) in tabs">
                    <router-link :class="{ selected: $route.name == 'rugby_match_detail.'+tab}" :to="{ name: 'rugby_match_detail.'+tab}">
                      {{tab == 'teamhistory' ? 'History' : tab}}
                    </router-link>
                </li>
            </ul>
            <a v-if="(selectedTabIndex+1) < tabs.length" @click="scrollRight()" aria-label="next arrow" class="next_btn_dtl_tabs" href="javascript:;">
                <img alt="next arrow" height="21"
                     :src="require('@/assets/images/slider_next_arrow.webp')" width="12">
            </a>
        </div>
        <router-view/>
    </div>
</template>


<script>
    import moment from 'moment';

    export default {
        name: "MatchDetail",
        data() {
            return {
                match: null,
                match_started:false,
                tabs: null,
                selectedTabIndex: 0,
                goalType: {home: 'PEN', away: 'PEN'},
                left: null,
                remainingTimeInterval: '',
            }
        },
        mounted() {
            this.getDetail();

            // setTimeout(() => {
                this.remainingTimeInterval = setInterval(() => {
                    this.remainingTime();
                }, 1000);
            // }, 1000);

        },
        computed: {
          capitalizeSelectedTab() {
              // converting first letter to uppercase
              let capitalized = '';
              if(this.tabs[this.selectedTabIndex] == 'teamhistory'){
                capitalized = 'History';
              } else {
                capitalized = this.tabs[this.selectedTabIndex].charAt(0).toUpperCase() + this.tabs[this.selectedTabIndex].slice(1);
              }
              return capitalized;
          }
        },
        beforeDestroy() {
            clearInterval(this.remainingTimeInterval);
        },
        serverPrefetch() {
            return this.getDetail();

        },
        methods: {
            //1695319  this.$route.params.matchId
            async getDetail() {
                await this.$axios
                    .get(this.$RUGBY_MATCH_DETAIL_API.replace(':match', this.$route.params.matchId))
                    .then(response => {
                        this.match = response.data.match;
                        this.makeTimeLineScorers(this.match.goals);
                        if(this.match.status=='NSY' && !this.match_started){
                            this.remainingTime();
                        }
                        // Trigger mutation handler below
                        this.$store.commit({
                            type: 'setRugbyMatchDetailResponse',
                            data: this.match,
                        });
                    })
                    .catch(error => console.log(error))

                return this.$axios
                    .get(this.$RUGBY_MATCH_DETAIL_TABS_API.replace(':match', this.$route.params.matchId))
                    .then(response => {
                        this.tabs = response.data.result;
                        let splitted_route = this.$route.name.split('.');
                        if(splitted_route && splitted_route[1]) {
                            this.selectedTabIndex = this.tabs.indexOf(splitted_route[1]);
                            // console.log(this.selectedTabIndex);
                        }
                    })
                    .catch(error => console.log(error));
            },
            calculateFinalScore(ft, ht) {
                var ft_score = ft.split('-');
                var ht_score = ht.split('-');
                return ((ft_score[0].trim() - ht_score[0].trim()) + ' - ' + (ft_score[1].trim() - ht_score[1].trim()));
            },
            scrollRight() {
                if((this.selectedTabIndex+1) != this.tabs.length) {
                  this.selectedTabIndex += 1;
                  console.log(this.selectedTabIndex);
                  this.$router.push({ name: 'rugby_match_detail.'+this.tabs[this.selectedTabIndex]})
                }
                // return false;
                // this.widgetsContent.nativeElement.scrollTo({ left: (this.widgetsContent.nativeElement.scrollLeft + 150), behavior: 'smooth',block: "start", inline: "nearest" });
            },
            scrollLeft() {
                if(this.selectedTabIndex != 0) {
                  this.selectedTabIndex -= 1;
                  console.log(this.selectedTabIndex);
                  this.$router.push({ name: 'rugby_match_detail.'+this.tabs[this.selectedTabIndex]})
                }
                // return false;
                // this.widgetsContent.nativeElement.scrollTo({ left: (this.widgetsContent.nativeElement.scrollLeft - 150), behavior: 'smooth', block: "start", inline: "nearest" });
            },
            remainingTime() {
                var a = moment();
                var b = moment(this.match.date);
                var diff = b.diff(a, 'seconds');
                // diff = Math.abs(diff);
                if(diff < 0){
                  this.match_started = true;
                  clearInterval(this.remainingTimeInterval);
                  return '';
                }
                var day = 86400;
                var hour = 3600;
                var minute = 60;

                var totalseconds = diff;
                if (this.left == null) {
                    this.left = {days:'',hours:'',minutes:'',seconds:''}
                }

                this.left.days = Math.floor(totalseconds / day);
                this.left.days = this.left.days > 9 ? this.left.days : '0'+this.left.days;
                this.left.hours = Math.floor((totalseconds - this.left.days * day) / hour);
                this.left.hours = this.left.hours > 9 ? this.left.hours : '0'+this.left.hours;
                this.left.minutes = Math.floor((totalseconds - this.left.days * day - this.left.hours * hour) / minute);
                this.left.minutes = this.left.minutes > 9 ? this.left.minutes : '0'+this.left.minutes;
                this.left.seconds = totalseconds - this.left.days * day - this.left.hours * hour - this.left.minutes * minute;
                this.left.seconds = this.left.seconds > 9 ? this.left.seconds : '0'+this.left.seconds;

                return this.left;
            },
            filterGoal(scores, type) {
                const result = scores.filter(goal => goal.type == type);
                return result;
            },
            makeTimeLineScorers(arr) {
                let key1 = '';
                for (key1 in arr) {
                    arr[key1].cal_minute = Math.floor(((arr[key1].minutes / 80) * 100));
                }
            }
        }
    }
</script>
`
